import styled from 'styled-components'
import { darkBlueGreyBorder, greyIsh, darkBlueGrey, errorValidation } from 'components/baseColor'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.error ? '0px' : '12px')};

  input {
    width: 100%;
    border: none;
  }

  input:focus {
    outline: none;
    border: 1px solid ${(props) => (props.error ? 'none' : darkBlueGreyBorder)};
    box-shadow: 0px 0px 2px 0px ${(props) => (props.error ? 'none' : darkBlueGreyBorder)};
  }

  span {
    font-size: 14px;
    margin-top: 0.5rem;
  }

  span: nth-child(1) {
    color: ${darkBlueGrey};
    margin-bottom: 3px;
  }

  span: nth-child(3) {
    color: ${errorValidation};
  }

  > .intl-tel-input {
    border: 1px solid ${({ error }) => (!error ? '#bcbfc8' : errorValidation)};
    border-radius: 4px;

    .flag-container {
      > .selected-flag {
        display: grid;
        background-color: transparent;
        grid-template-areas: "flag arrow dial-code";

        > .selected-dial-code {
          padding-left: 10px;
          grid-area: dial-code;
        }

        > .iti-flag {
          grid-area: flag;
        }

        > .arrow {
          grid-area: arrow;
          color: ${greyIsh};
          font-size: 10px;
          height: 12px;
        }
      }
    }
  }
`
