import React from "react";
import { FormattedMessage as FormatMessageIntl, useIntl as useReactIntl } from "react-intl";

import { LANGUAGE_SOURCE } from "./useLanguage";

const DEFAULT_MESSAGE = "en";

export function useIntl(params) {
  const getDefaultMessage = LANGUAGE_SOURCE[DEFAULT_MESSAGE][params.id];
  const configs = { defaultMessage: getDefaultMessage, ...params };

  return useReactIntl(configs);
}

export function FormattedMessage(props) {
  const getDefaultMessage = LANGUAGE_SOURCE[DEFAULT_MESSAGE][props.id];

  return <FormatMessageIntl defaultMessage={getDefaultMessage} {...props} />;
}
