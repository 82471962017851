import styled, { css } from 'styled-components'
import { darkBlueGrey, mykonosBlue } from 'components/baseColor'
import { IS_FRAME_DESKTOP, IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const BaseHeader = styled.header`
  height: 77px;
`

export const Fixed = styled.div`
  background-color: ${(props) => props.bgColor || mykonosBlue};
  position: fixed;
  width: 100%;
  z-index: 2;

  @media only screen and (max-width: 768px) {
    background-color: ${mykonosBlue};
  }
`

export const Wrapper = styled.div`
  padding: 1em 3em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: all 0.5s ease-in-out;
  max-width: 1600px;
  margin: auto;

  img {
    width: 70px;
  }

  .pointer {
    cursor: pointer;
  }

  ${IS_FRAME_TABLET} {
    justify-content: start;
    flex-direction: column;
    padding: 1rem 1.5rem;

    &.open {
      height: 100vh;
    }
    &.open .menus {
      display: flex;
      flex-direction: column;
    }
    &.open .hamburger-icon {
      transform: translateX(-45px);
      background: transparent;
    }
    &.open .hamburger-icon:before {
      transform: rotate(45deg) translate(30px, -30px);
    }
    &.open .hamburger-icon:after {
      transform: rotate(-45deg) translate(30px, 30px);
    }
  }

  ${IS_FRAME_MOBILE} {
    justify-content: start;
    flex-direction: column;
    padding: 1rem 1.5rem;

    &.open {
      height: 100vh;
    }
    &.open .menus {
      display: flex;
      flex-direction: column;
    }
    &.open .hamburger-icon {
      transform: translateX(-45px);
      background: transparent;
    }
    &.open .hamburger-icon:before {
      transform: rotate(45deg) translate(30px, -30px);
    }
    &.open .hamburger-icon:after {
      transform: rotate(-45deg) translate(30px, 30px);
    }
  }
`

export const Menus = styled.ul`
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${({ isInputFocus }) => isInputFocus && 'width: 100%; '}
  width: 80%;

  li {
    margin-right: 44px;

    a {
      font-family: 'Open Sans',system-ui,-apple-system;
      color: #ffffff;
      text-decoration: unset;
      font-size: 1em;
      font-weight: bold;
      text-transform: uppercase;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  ${IS_FRAME_TABLET} {
    display: none;
    align-items: flex-start;
    width: 100%;
    padding-left: 0;

    li {
      width: 100%;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;

      a {
        font-size: 1rem;
      }
    }
  }

  ${IS_FRAME_MOBILE} {
    display: none;
    align-items: flex-start;
    width: 100%;
    padding-left: 0;

    li {
      width: 100%;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;

      a {
        font-size: 1rem;
      }
    }
  }
`

export const BaseButton = styled.div`
  display: flex;

  > :nth-of-type(1) {
    margin-right: 20px;
  }

  ${IS_FRAME_TABLET} {
    flex-direction: column;

    > :nth-of-type(1) {
      margin-right: 0;
      margin-bottom: 20px;
    }

    button {
      width: 100%;
    }
  }

  ${IS_FRAME_MOBILE}{
    flex-direction: column;

    > :nth-of-type(1) {
      margin-right: 0;
      margin-bottom: 20px;
    }

    button {
      width: 100%;
    }
  }
`

export const HamburgerContainer = styled.div`
  display: none;

  ${IS_FRAME_TABLET} {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 36px;
    height: 36px;
    transition: all 0.5s ease-in-out;
  }

  ${IS_FRAME_MOBILE} {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 36px;
    height: 36px;
    transition: all 0.5s ease-in-out;
  }
`

export const HamburgerIcon = styled.div`
  width: 30px;
  height: 4px;
  background: white;
  transition: all 0.5s ease-in-out;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 4px;
    background: white;
    transition: all 0.5s ease-in-out;
  }

  &:before {
    transform: translateY(-8px);
  }

  &:after {
    transform: translateY(8px);
  }
`

export const MobileWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  ${IS_FRAME_TABLET} {
    width: 100%;
  }

  ${IS_FRAME_MOBILE}{
    width: 100%;
  }
`

export const DesktopWrapper = styled.div`
  display: block;
  text-align: right;

  ${IS_FRAME_TABLET} {
    display: none;
  }

  ${IS_FRAME_MOBILE} {
    display: none;
  }
`

export const DropdownButton = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Open Sans',system-ui,-apple-system;
  color: #ffffff;
  text-decoration: unset;
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    ${IS_FRAME_DESKTOP} {
      top: 12%;
      right: -20px;
      border: solid white;
      border-width: 0 2px 2px 0;
      padding: 2.5px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }

    ${IS_FRAME_TABLET} {
      width: 100%;
      border-width: 0;
      top: 50%;
      right: 6px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 7px solid white;
    }

    ${IS_FRAME_MOBILE} {
      width: 100%;
      border-width: 0;
      top: 50%;
      right: 6px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 7px solid white;
    }
  }

  > img {
    height: 23px;
    width: 23px;
    object-fit: contain;
  }

  ${IS_FRAME_TABLET} {
    font-size: 1rem;
  }

  ${IS_FRAME_MOBILE} {
    font-size: 1rem;
  }
`

export const DropdownMenu = styled.div`
  transition: all 0.5s ease-in-out;
  display: none;
  position: absolute;
  top: 2.5em;
  right: -20px;
  z-index: 1000;
  min-width: 8rem;
  background-color: white;
  border: solid 1px ${darkBlueGrey};
  border-radius: 4px;
  padding: 0.5rem 0;

  ${({ position }) => position === 'right' &&
    css`
      right: unset;
      left: -16px;
    `}

  &.open {
    display: block;
  }

  > .dropdown-item {
    display: block;
    padding: 0.5rem 1rem;
    color: ${darkBlueGrey};
    text-align: right;
    white-space: nowrap;
    background-color: transparent;
    text-transform: unset;

    ${IS_FRAME_MOBILE} {
      padding-left: 0;
      font-size: 0.8rem;
    }

    ${({ position }) => position === 'right' &&
      css`
        text-align: left;
      `}
  }

  > a:hover {
    background-color: lightgray;
  }

  > a > img {
    height: 1em;
    width: 1.25em;
    vertical-align: top;
  }

  @media only screen and (max-width: 768px) {
    position: static;
    margin-top: 1rem;

    > .dropdown-item {
      text-align: left;
      margin: 0 1rem;
    }

    > .dropdown-item:not(:last-child) {
      border-bottom: solid 1px ${darkBlueGrey};
    }
  }

  ${IS_FRAME_TABLET} {
    position: static;
    margin-top: 1rem;

    > .dropdown-item {
      text-align: left;
      margin: 0 1rem;
    }

    > .dropdown-item:not(:last-child) {
      border-bottom: solid 1px ${darkBlueGrey};
    }
  }
`

export const Dropdown = styled.li`
  position: relative;


  ${IS_FRAME_TABLET} {
    display: block;
  }

  ${IS_FRAME_MOBILE} {
    display: block;
  }
`

export const Li = styled.li`

  white-space: nowrap;

  ${IS_FRAME_MOBILE} {
    display: block;
  }
`

export const StyledInput = styled.div`
  position: relative;
  margin: 0 1rem 0 0;

  ${IS_FRAME_TABLET} {
    margin: 0;
  }

  ${IS_FRAME_MOBILE} {
    margin: 0;
  }
`

export const InputSearch = styled.input`
  border: none;
  background: rgb(255, 255, 255, 0.5);
  padding: 0.5rem 2.5rem 0.5rem 0.5rem;
  color: #fff;

  &:focus {
    border: none;
    outline: none;
  }
  &::-webkit-search-decoration:hover,
  &::-webkit-search-cancel-button:hover,
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  ${IS_FRAME_TABLET} {
    width: 100%;
  }

  ${IS_FRAME_MOBILE} {
    width: 100%;
  }
`

export const SearchIcon = styled.div`
  position: absolute;
  right: ${({ isKeyword }) => (isKeyword ? '1.75rem' : '0.5rem')};
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  opacity: 0.7;
  cursor: pointer;
  display: ${({ isInputFocus }) => (isInputFocus ? 'none' : 'block')};

  > svg {
    width: 0.75rem;
    height: 0.75rem;

    path {
      fill: #fff;
    }
  }
`

export const CloseIcon = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  opacity: 0.7;
  display: ${({ isDisplay }) => (isDisplay ? 'block' : 'none')};
  cursor: pointer;

  > svg {
    width: 1rem;
    height: 1rem;

    path {
      fill: #fff;
    }
  }
`
