import styled from "styled-components";
import { darkBlueGrey } from "components/baseColor";

export const Container = styled.div`
  .checkbox-label {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    line-height: 24px;
    height: 20px;
    width: 20px;
    clear: both;
    margin-top: 2px;
  }

  .checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkbox-label .checkbox-custom {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 15px;
    width: 15px;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid grey;
  }

  .checkbox-label input:checked ~ .checkbox-custom {
    border-radius: 5px;
    transform: rotate(0deg) scale(1);
    opacity: 1;
    border: 1px solid grey;
  }

  .checkbox-label .checkbox-custom::after {
    position: absolute;
    content: "";
    left: 6px;
    top: 6px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid ${darkBlueGrey};
    border-width: 0 3px 3px 0;
    transform: rotate(0deg) scale(0);
    opacity: 1;
    transition: all 0.3s ease-out;
  }

  .checkbox-label input:checked ~ .checkbox-custom::after {
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 5px;
    top: 3px;
    width: 3px;
    height: 6px;
    border: solid ${darkBlueGrey};
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
  }

  /* For Ripple Effect */
  .checkbox-label .checkbox-custom::before {
    position: absolute;
    content: "";
    left: 10px;
    top: 10px;
    width: 0px;
    height: 0px;
    border-radius: 5px;
    border: 2px solid grey;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
`;
