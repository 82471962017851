import React from 'react'
import { GlobalStyles } from './styles'
import Header from '../Header'
import Footer from '../Footer'
import PropTypes from 'prop-types'
import data from '../../../package.json'

const Layout = ({ children, headerColor, location }) => (
  <>
    <GlobalStyles />
    <div style={{ display: 'none' }}>CF-VERSION: { data.version }</div>
    <Header bgColor={ headerColor } location={ location } />
    <main>{ children }</main>
    <Footer location={ location } />
  </>
)

Layout.propTypes = {
  children    : PropTypes.node.isRequired,
  headerColor : PropTypes.string
}

export default Layout
