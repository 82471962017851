import styled, { css } from 'styled-components'
import { darkBlueGrey, errorValidation, whiteTwo } from '../baseColor'

const padding = 'padding: 9px 0;'

export const Base = styled.div`
  position: relative;
  width: ${({ width }) => width || '100%'};
  margin-top: 0.25rem;
`

export const WrapperInput = styled.div`
  cursor: pointer;
  border-radius: 4px;
  padding: ${({ py }) => py || '9px'} 10px;
  overflow: hidden;
  border: solid 1px ${({ borderColor }) => borderColor || darkBlueGrey};
  display: grid;
  grid-template-columns: 0.8fr max-content;
  align-items: center;
  justify-content: space-between;
  height: 22px;
  margin-bottom: 3px;
  font-size: 14px;
  color: ${darkBlueGrey};

  > svg {
    position: absolute;
    right: ${({ svgPosition }) => svgPosition.right || '7px'};
    top: ${({ svgPosition }) => svgPosition.top || '10px'};

    > g path:nth-of-type(1) {
      fill: ${darkBlueGrey};
    }
  }
`

export const Input = styled.input`
  cursor: pointer;
  height: inherit;
  border: none;
  width: 100%;
  height: 100%;
  font-weight: normal;
  color: ${({ color }) => color || darkBlueGrey};
  white-space: nowrap;
  text-overflow: ellipsis;
  outline: none;
`

export const Ul = styled.ul`
  overflow: hidden auto;
  max-height: 14em;
  margin: 0;
  padding: 0;
`

export const Li = styled.li`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${darkBlueGrey};
  height: 100%;
  ${padding}
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;

  ${({ isChecked }) => isChecked &&
    css`
      background-color: ${whiteTwo};
    `}

  svg {
    background-color: ${darkBlueGrey};
    border-radius: 100%;

    > g path:nth-of-type(2) {
      fill: #fff;
    }
  }

  &:hover {
    background-color: ${whiteTwo};
  }
`

export const ErrorText = styled.span`
  color: ${errorValidation};
  margin-top: 3px;
`

export const WrapperList = styled.div`
  position: absolute;
  z-index: 2;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border: solid 0.5px ${darkBlueGrey};
  background-color: #fff;
  margin-top: 4px;
  width: 99.4%;
`

export const Search = styled.input`
  border-radius: 4px;
  border: solid 0.5px ${darkBlueGrey};
  height: 25px;
  padding: 0 6px;
  margin: 7px;
  width: 90%;
  outline: none;
`
