export const mykonosBlue = '#1160fb'
export const darkBlueGrey = '#35405a'
export const darkBlueGreyBorder = 'rgba(34, 43, 73, 0.5)'
export const lightDarkBlue = '#364159'
export const lightGold = '#ffda5f'
export const errorValidation = '#e53935'
export const paleGrey = '#f6f7f8'
export const midnightBlue = '#050038'
export const snow = '#fbfbfb'
export const regentGray = '#8697a7'
export const emerald = '#11b683'
export const lightGrey = '#9095a4'
export const lightShadeGrey = '#eeeeee'
export const shadeGrey = '#878c9b'
export const coolGrey = '#8f9198'
export const whiteTwo = '#e9e9e9'
export const greenColor = '#00bd9c'
export const warmGrey = '#979797'
export const greyIsh = '#a3a3a3'
export const disabledColor = '#bcbfc8'
export const greyishBrown = '#3f3f3f'
export const cerulean = '#0185d4'
export const moonYellow = '#f2c420'
export const isGreen = '#06ab02'