import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Container, Input } from './style'

const InputNumber = forwardRef(({ height, withSpaceError = true, ...props }, ref) => {
  const { title, error } = props

  const handleBlur = (e) => {
    props.onBlur(e.target.name, e.target.value)
  }

  return (
    <Container { ...{ height, withSpaceError, error } }>
      <span>{ title }</span>
      <Input { ...props } type='number' onBlur={ handleBlur } />
      { error && <span style={{ marginBottom: '0.75rem' }}>{ error }</span> }
    </Container>
  )
})

InputNumber.defaultProps = {
  type        : 'text',
  placeholder : 'what are you thinking?',
  title       : '',
  error       : '',
  value       : '',
  name        : '',
  onBlur      : () => {},
  onChange    : () => {}
}

InputNumber.propTypes = {
  title       : PropTypes.string,
  type        : PropTypes.string,
  placeholder : PropTypes.string,
  error       : PropTypes.string,
  value       : PropTypes.string,
  onBlur      : PropTypes.func.isRequired,
  onChange    : PropTypes.func
}
export default InputNumber
