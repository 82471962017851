import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'gatsby'
import API from 'commons/API'
import {
  LogoQlue,
  IconLocation
  // Phone,
} from '@qlue/icon-kit'
import { 
  Wrapper, 
  Flex,
  Sosmed 
} from './styles'
import { 
  QUICK_LINKS, 
  SOLUTIONS,
  AFFILIATES,
  HELP, 
  SOSMED
} from './constants'
import {
  generateElementID, 
  FOOTER_ID_PREFIX,
  CONTENT_TEXT_ID_PREFIX,
  CONTENT_ID_PREFIX,
  CONTENT_AFFILIATES_ID_PREFIX
} from 'utils/generateElementID'

function Footer({ location }) {

  const intl = useIntl()

  const [country, setCountry] = useState('')

  useEffect(() => {
    const getVisitorCountry = async () => {
      try {
        const { data: ipUser } = await API('https://api.ipify.org/?format=json')
        const { data: dataLocation } = await API(`https://ipapi.co/${ipUser.ip}/json/`)
        if (country !== dataLocation.country_name) {
          setCountry(dataLocation.country_name)
          sessionStorage.setItem('geoLocation', JSON.stringify(dataLocation))
        }
      } catch (err) {
        console.log('--- error getting current location', err.message)
      }
    }

    const geoLocation = sessionStorage.getItem('geoLocation')
    if(!geoLocation) getVisitorCountry()
    else {
      const countryName = JSON.parse(geoLocation).country_name
      setCountry(countryName)
    }
  }, [country])

  const CompanyAddress = ({ country }) => {
    const localAddress = (
      <>
        <div>
          PT. Qlue Performa Indonesia
        </div>
        <div id={ generateElementID(CONTENT_TEXT_ID_PREFIX, 'detailalamatkantorqlue') }>
          Jalan Pejaten Barat No. 13,RT.1/RW.8, Pejaten Barat, Kota Jakarta Selatan, DKI Jakarta, 12510
        </div>
      </>
    )
    const intlAddress = '133 New Bridge Road #10-03, Chinatown Point Singapore 059413'
    const address = country === 'Indonesia' ? localAddress : intlAddress

    return <span>{ address }</span>
  }

  const isCartPage = location?.pathname === '/cart'

  return (
    <Wrapper isCartPage={ isCartPage }>

      { /* col 1 */ }
      <div>
        <LogoQlue id={ generateElementID(FOOTER_ID_PREFIX, 'logoqlue') } />
        <Flex>
          <IconLocation id={ generateElementID(FOOTER_ID_PREFIX, 'iconalamatkantorqlue') } />
          <CompanyAddress country={ country } />
        </Flex>

        <div className='wrapper-sosmed'>
          {
            SOSMED.map((item) => (
              <Sosmed
                key={ item.name } 
                href={ item.href }
                target='_blank' 
                rel='noreferrer' 
                style={{ position: 'relative' }}
              >
                { item.icon }
                <div className='tooltip'>
                  { item.name }
                </div>
              </Sosmed>
            ))
          }
        </div>
      </div>

      { /* col 2 */ }
      <div className='each-section'>
        <strong id={ generateElementID(CONTENT_ID_PREFIX, 'quicklinks') } className='title'>
          { intl.formatMessage({ id: '2171444a1' }).toLocaleLowerCase() }
        </strong>
        <ul>
          {
            QUICK_LINKS.map((link) => (
              link.path 
                ? (
                  <Link 
                    key={ link.name }
                    to={ link.path }
                  >
                    <li id={ link.idQuickLinks }>
                      { intl.formatMessage({ id: link.name }).toLocaleLowerCase() }
                    </li>
                  </Link>
                )
                : (
                  <a 
                    key={ link.name }
                    href={ link.href }
                  >
                    <li id={ link.idQuickLinks }>
                      { intl.formatMessage({ id: link.name }).toLocaleLowerCase() }
                    </li>
                  </a>
                )
            ))
          }
        </ul>
      </div>

      { /* col 3 */ }
      <div className='each-section'>
        <strong id={ generateElementID(CONTENT_ID_PREFIX, 'solutions') } className='title'>
          { intl.formatMessage({ id: '5d74f1187' }).toLocaleLowerCase() }
        </strong>
        <ul>
          {
            SOLUTIONS.map((link) => (
              <Link 
                key={ link.name }
                to={ link.path }
              >
                <li id={ link.idSolutions }>
                  { intl.formatMessage({ id: link.name }).toLocaleLowerCase() }
                </li>
              </Link>
            ))
          }
        </ul>
      </div>

      { /* col 4 */ }
      <div className='each-section'>
        <div className="flexAffiliate">
          <strong id={ generateElementID(CONTENT_ID_PREFIX, 'affiliates') } className='title'>
            { intl.formatMessage({ id: '8fe5d8f7a' }).toLocaleLowerCase() }
          </strong>
          <ul>
            {
              AFFILIATES.map((link) => (
                <Link 
                  key={ link.name }
                  to={ link.path }
                >
                  <li id={ generateElementID(CONTENT_AFFILIATES_ID_PREFIX, 'menuaffiliatelogin') }>
                    { intl.formatMessage({ id: link.name }).toLocaleLowerCase() }
                  </li>
                </Link>
              ))
            }
          </ul>
        </div>

        <div>
          <strong id={ generateElementID(CONTENT_ID_PREFIX, 'help') } className='title'>
            { intl.formatMessage({ id: '9dfb43898' }).toLocaleLowerCase() }
          </strong>
        
          <ul>
            {
              HELP.map((link) => (
                <Link 
                  key={ link.name }
                  to={ link.path }
                >
                  <li id={ link.idHelp }>
                    { intl.formatMessage({ id: link.name }).toLocaleLowerCase() }
                  </li>
                </Link>
              ))
            }
          </ul>
        </div>
      </div>
    </Wrapper>
  )
}

export default Footer
