import React from 'react'
import {
  IconFacebook,
  IconInstagram,
  IconTwitter,
  IconLinkedin,
  IconWeb,
  IconWhatsapp
} from '@qlue/icon-kit'
import { 
  generateElementID, 
  FOOTER_ID_PREFIX, 
  CONTENT_QUICK_LINKS_ID_PREFIX,
  CONTENT_SOLUTIONS_ID_PREFIX,
  CONTENT_AFFILIATES_ID_PREFIX,
  CONTENT_HELP_ID_PREFIX 
} from 'utils/generateElementID'

const articleURL = process.env.GATSBY_ARTICLE_URL

export const QUICK_LINKS = [
  {
    name         : '58b782d18',
    path         : '/',
    idQuickLinks : generateElementID(CONTENT_QUICK_LINKS_ID_PREFIX, 'menuhome')
  },
  // {
  //   name         : 'd3ceb1bed',
  //   path         : '/pricing',
  //   idQuickLinks : generateElementID(CONTENT_QUICK_LINKS_ID_PREFIX, 'menupricing')
  // },
  {
    name         : '125130f88',
    path         : articleURL,
    idQuickLinks : generateElementID(CONTENT_QUICK_LINKS_ID_PREFIX, 'menuarticles')
  },
  {
    name         : 'e8e0e324a',
    path         : '/register',
    idQuickLinks : generateElementID(CONTENT_QUICK_LINKS_ID_PREFIX, 'menuregister')
  },
  {
    name         : '1d5ab5e37',
    href         : process.env.GATSBY_DASHBOARD_URL,
    idQuickLinks : generateElementID(CONTENT_QUICK_LINKS_ID_PREFIX, 'menulogin')
  }
]

export const SOLUTIONS = [
  {
    name        : '8e44f019d',
    path        : '/solution/smart-workforce-management',
    idSolutions : generateElementID(CONTENT_SOLUTIONS_ID_PREFIX, 'menusmartworkforcemanagement')
  },
  {
    name        : '2bdcfb17a',
    path        : '/solution/human-detection-and-recognition',
    idSolutions : generateElementID(CONTENT_SOLUTIONS_ID_PREFIX, 'menuhumandetectionandrecognition')
  },
  {
    name        : 'c9b8e2233',
    path        : '/solution/vehicle-detection-and-recognition',
    idSolutions : generateElementID(CONTENT_SOLUTIONS_ID_PREFIX, 'menuvehicledetectionandrecognition')
  }
]

export const AFFILIATES = [
  {
    name         : '3cc361ccb',
    path         : '/affiliate',
    idAffiliates : generateElementID(CONTENT_AFFILIATES_ID_PREFIX, 'menubecomeanaffiliate')
  },
  {
    name         : '95a86ea89',
    path         : '/affiliate-dashboard/login',
    idAffiliates : generateElementID(CONTENT_AFFILIATES_ID_PREFIX, 'menuaffiliatelogin')
  }
]

export const HELP = [
  {
    name   : '7f91830c5',
    path   : '/privacy-policy',
    idHelp : generateElementID(CONTENT_HELP_ID_PREFIX, 'privacyandpolicy')
  },
  {
    name   : 'db0e33dff',
    path   : '/term-service',
    idHelp : generateElementID(CONTENT_HELP_ID_PREFIX, 'termsandcondition')
  }
]

export const SOSMED = [
  {
    name : 'WhatsApp',
    href : 'https://wa.me/6590426198',
    icon : <IconWhatsapp id={ generateElementID(FOOTER_ID_PREFIX, 'iconwhatsaap') } />
  },
  {
    name : 'Facebook',
    href : 'https://www.facebook.com/qluesmartcity',
    icon : <IconFacebook id={ generateElementID(FOOTER_ID_PREFIX, 'iconfacebook') } />
  },
  {
    name : 'Instagram',
    href : 'https://www.instagram.com/qluesmartcity',
    icon : <IconInstagram id={ generateElementID(FOOTER_ID_PREFIX, 'iconinstagram') } />
  },
  {
    name : 'Twitter',
    href : 'https://www.twitter.com/qluesmartcity',
    icon : <IconTwitter id={ generateElementID(FOOTER_ID_PREFIX, 'icontwitter') } />
  },
  {
    name : 'Linkedin',
    href : 'https://www.linkedin.com/company/qluesmartcity/',
    icon : <IconLinkedin id={ generateElementID(FOOTER_ID_PREFIX, 'iconlinkedin') } />
  },
  {
    name : 'Official Website',
    href : 'https://www.qlue.co.id',
    icon : <IconWeb id={ generateElementID(FOOTER_ID_PREFIX, 'iconofficialwebsite') } />
  }
]
