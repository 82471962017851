import React from 'react'
import { Container } from './style'

const Checkbox = ({ 
  handleClick,
  checked 
}) => (
  <Container>
    <label className="checkbox-label">
      <input 
        checked={ checked }
        tabIndex="-1" 
        type="checkbox" 
        onClick={ handleClick } 
      />
      <span className="checkbox-custom rectangular"></span>
    </label>
  </Container>
)


export default Checkbox
