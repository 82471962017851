import styled, { keyframes, css } from 'styled-components'
import { darkBlueGreyBorder, darkBlueGrey, errorValidation } from 'components/baseColor'

const shakes = keyframes`
    0% {
      margin-left: 0px;
    }
    25% {
      margin-left: 1px;
    }
    50% {
      margin-left: -1px;
    }
    100% {
      margin-left: 0px;
    }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  animation-name: ${(props) => props.error && shakes};
  animation-duration: 0.4s;
  position: relative;
  margin: 0.5rem 0;

  input {
    border-radius: 2px;
    border: solid 1px ${(props) => (props.error ? errorValidation : '#bcbfc8')};
    height: ${({ height }) => height || '24px'};
    padding: 3px 0px 3px 10px;
    margin: 0.25rem 1px 3px 0px;
    color: ${darkBlueGrey};
    font-size: 14px;
  }
  input:focus {
    outline: none;
    border: 1px solid ${(props) => (props.error ? errorValidation : darkBlueGreyBorder)};
    box-shadow: 0px 0px 2px 0px ${(props) => (props.error ? errorValidation : darkBlueGreyBorder)};
  }
  span: nth-child(1) {
    color: ${darkBlueGrey};
  }

  span: nth-child(3) {
    color: ${errorValidation};
  }

  ${({ withSpaceError, error }) => withSpaceError &&
    css`
      margin-bottom: ${error ? '0px' : '12px'};
    `}
`

export const Input = styled.input`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  -moz-appearance:textfield;
`