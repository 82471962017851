import styled, { css } from 'styled-components'
import { 
  darkBlueGrey, 
  lightGold, 
  mykonosBlue 
} from 'components/baseColor'

export const BaseComponent = styled.button`
  font-size: 1em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border-radius: 4px;
  overflow: hidden;
  padding: 0.5em 2em;
  border: solid 1px ${darkBlueGrey};
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
  color: ${darkBlueGrey};
  width: ${(props) => props.width || 'unset'};
  height: ${(props) => props.height || 'unset'};

  svg > path {
    fill: ${darkBlueGrey};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:not([disabled]):hover {
    color: #ffffff;
    background-color: ${lightGold};
    border: solid 1px #fff;
  }

  ${(props) => props.buttonType === 'light' &&
    css`
      background-color: #fff;
      color: ${darkBlueGrey};
      border: solid 1px #fff;

      &:not([disabled]):hover {
        color: #ffffff;
        background-color: ${mykonosBlue};
        border: solid 1px #fff;
      }
    `}

  ${(props) => props.buttonType === 'primary' &&
    css`
      background-color: #fff;
      color: ${mykonosBlue} ;
      border: solid 1px ${mykonosBlue};

      &:not([disabled]):hover {
        color: #ffffff;
        background-color: ${mykonosBlue};
        border: solid 1px ${mykonosBlue};
      }
    `}

  ${(props) => props.buttonType === 'warning' &&
    css`
      background-color: ${lightGold};
      color: ${darkBlueGrey};
      border: solid 1px ${lightGold};

      &:not([disabled]):hover {
        color: #fff;
        background-color: ${mykonosBlue};
        border: solid 1px #fff;
      }
    `}
`
