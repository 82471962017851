import React from 'react'
import { Steps, Step, Line, TextWrapper, Text } from './styles'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

const propTypes = {
  menu: PropTypes.shape({
    list   : PropTypes.object,
    margin : PropTypes.shape({
      m1: PropTypes.string
    })
  })
}

const defaultProps = {
  menu: {
    list: {
      Checkout: {
        // text below the step (circle)
        step: {
          onClick   : () => navigate('/checkout'), // func when clicked the step
          selected  : '', // selected condition
          completed : '' // completed condition
        },
        line: {
          active: '' // line active condition
        }
      },
      Payment: {
        step: {
          onClick  : () => navigate('/payment/confirmation'),
          selected : ''
        },
        line: {
          active: ''
        }
      },
      Confirmation: {
        step: {
          onClick  : () => navigate('/payment'),
          selected : '',
          active   : ''
        }
        // because of last menu doesn't have line, you don't need passing line object
      }
    },
    margin: {
      m1 : '0 0 0 1rem', // margin for text (centering text below the step (circle))
      m2 : '',
      m3 : ''
    }
  }
}

const Timeline = ({ menu }) => (
  <>
    <Steps>
      { Object.keys(menu.list).map((key, index) => (
        <React.Fragment key={ `menu-${index}` }>
          <div>
            <Step
              onClick={ menu.list[key].step.onClick }
              selected={ menu.list[key].step.selected }
              completed={ menu.list[key].step.completed }
            />
          </div>
          { index < Object.keys(menu.list).length - 1 ? (
            <Line active={ menu.list[key].line.active } />
          ) : null }
        </React.Fragment>
      )) }
    </Steps>

    <TextWrapper>
      { Object.keys(menu.list).map((key, index) => (
        <Text key={ key } bold m={ menu.margin[`m${index + 1}`] }>
          { key }
        </Text>
      )) }
    </TextWrapper>
  </>
)

Timeline.propTypes = propTypes
Timeline.defaultProps = defaultProps

export default Timeline
