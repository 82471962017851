import styled from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

const MobileOnly = styled.div`
  display: none;

  ${IS_FRAME_TABLET} {
    display: block;
  }

  ${IS_FRAME_MOBILE} {
    display: block;
  }
`

export default MobileOnly
