import React from "react";
import PropTypes from "prop-types";
import { Wrapper } from "./styles";

function Shimmer({ height = "1.3em", width = "100%" }) {
  return <Wrapper width={width} height={height} />;
}

Shimmer.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Shimmer;
