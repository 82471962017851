import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { TriangleDown, Spinner, BlueChecklist } from '@qlue/icon-kit'

import { Base, Li, Ul, Input, WrapperInput, ErrorText, Search, WrapperList } from './styles'
import { isBrowser } from 'utils'
import { errorValidation } from '../baseColor'
import { useIntl } from 'react-intl'

function ListItem({ selectedKey, handleClickItem, value, ...option }) {
  const intl = useIntl()
  const refItem = useRef()
  const isChecked = (value && selectedKey) ? value[selectedKey] === option[selectedKey] : value === option

  useEffect(() => {
    const ele = refItem.current
    if (isChecked) ele.scrollIntoView({ block: 'nearest' })
  }, [isChecked])
  return (
    <Li ref={ refItem } isChecked={ isChecked } onClick={ handleClickItem(option) }>
      <span>{ option.name === 'Annually' || option.name === 'Monthly' ?  intl.formatMessage({ id: `${option.name}-xth` }) : option.name }</span>
      { value && (selectedKey ? value[selectedKey] === option[selectedKey] : value === option) && (
        <BlueChecklist width='10' height='10' />
      ) }
    </Li>
  )
}

const Select = forwardRef(({
  width,
  borderColor,
  errorText,
  py,
  colorText,
  isLoading,
  selectedItem = {},
  selectedKey,
  withSearch = false,
  options = [],
  keySearch = 'name',
  svgPosition = { right: '', top: '' },
  onChange = () => {},
  ...props
}, ref) => {
  const intl = useIntl()
  const [dataSearch, setDataSearch] = useState('')
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [value, setValue] = useState({})
  const filterableOptions = useMemo(
    () => options.filter((option) => {
      const regex = new RegExp(dataSearch, 'gi')
      return option[keySearch].search(regex) > -1
    }),
    [dataSearch, keySearch, options]
  )

  useEffect(() => {
    setValue(selectedItem)
  }, [selectedItem])

  function handleClickItem(data) {
    return () => {
      setValue(data)
      onChange(data)
    }
  }

  useEffect(() => {
    function onClickWindow(e) {
      if (isMenuOpen) setMenuOpen(false)
    }

    isBrowser && window.addEventListener('click', onClickWindow)
    return () => window.removeEventListener('click', onClickWindow)
  }, [isMenuOpen])
  return (
    <Base { ...{ width } }>
      <WrapperInput
        svgPosition={ svgPosition }
        borderColor={ errorText ? errorValidation : borderColor }
        py={ py }
        onClick={ () => (!isLoading ? setMenuOpen(true) : {}) }
      >
        { typeof value?.name === 'object' ? (
          value?.name
        ) : (
          <>
            <Input
              { ...props }
              color={ colorText }
              readOnly
              key={ value?.id }
              defaultValue={ value && (value.name === 'Annually' || value.name === 'Monthly') ? intl.formatMessage({ id: `${value.name}-xth` }) : value?.name }
            />
          </>
        ) }

        { isLoading ? <Spinner width='21' height='21' /> : <TriangleDown /> }
      </WrapperInput>
      { isMenuOpen && (
        <WrapperList>
          { withSearch && (
            <Search
              placeholder='Search...'
              onChange={ (e) => setDataSearch(e.target.value) }
              onClick={ (e) => e.stopPropagation() }
            />
          ) }
          <Ul>
            { filterableOptions.map((option, i) => (
              <ListItem
                key={ option?.id || i }
                { ...option }
                selectedKey={ selectedKey }
                value={ value }
                handleClickItem={ handleClickItem }
              />
            )) }
          </Ul>
        </WrapperList>
      ) }
      { errorText && <ErrorText>{ errorText }</ErrorText> }
    </Base>
  )
})

export default Select

