export const generateElementID = (...args) => args.join('-')

export const LOGIN_AFFILIATE_ID_PREFIX = 'loginaffiliate'
export const AFFILIATE_ID_PREFIX = 'affiliate'
export const FOOTER_ID_PREFIX = 'footer'
export const HEADER_ID_PREFIX = 'header'
export const MAIN_PAGE_ID_PREFIX = 'mainpage'

export const FORGOT_PASSWORD_ID_PREFIX = generateElementID(AFFILIATE_ID_PREFIX, 'forgotpassword')
export const SET_NEW_PASSWORD_ID_PREFIX = generateElementID(AFFILIATE_ID_PREFIX, 'setnewpassword')
export const FIELD_PASSWORD_ID_PREFIX = generateElementID(LOGIN_AFFILIATE_ID_PREFIX, 'fieldpassword')
export const CONTENT_AFFILIATES_ID_PREFIX = generateElementID(FOOTER_ID_PREFIX, 'contentaffiliates')
export const CONTENT_TITLE_ID_PREFIX = generateElementID(MAIN_PAGE_ID_PREFIX, 'contentttitle')
export const CONTENT_DESCRIPTION_ID_PREFIX = generateElementID(MAIN_PAGE_ID_PREFIX, 'contentdescription')
export const BUTTON_FREE_TRIAL_ID_PREFIX = generateElementID(MAIN_PAGE_ID_PREFIX, 'buttonfreetrial')
export const SOLUTION_SECTION_ID_PREFIX = generateElementID(MAIN_PAGE_ID_PREFIX, 'solutionsection')
export const IMAGE_SOLUTION_SECTION_ID_PREFIX = generateElementID(MAIN_PAGE_ID_PREFIX, 'image')
export const VEHICLE_DETECTION_RECOGNITION_ID_PREFIX = generateElementID(MAIN_PAGE_ID_PREFIX, 'vehicledetectionandrecognition')
export const PROBLEM_SOLVING_SECTION_ID_PREFIX = generateElementID(MAIN_PAGE_ID_PREFIX, 'problemsolvingsection')
export const BUTTON_REGISTER_NOW_ID_PREFIX = generateElementID(MAIN_PAGE_ID_PREFIX, 'buttonregisternow')
export const ADVANTAGE_SECTION_ID_PREFIX = generateElementID(MAIN_PAGE_ID_PREFIX, 'advantagesection')
export const ICON_ID_PREFIX = generateElementID(MAIN_PAGE_ID_PREFIX, 'icon')
export const TEXT_ICON_ID_PREFIX = generateElementID(MAIN_PAGE_ID_PREFIX, 'texticon')
export const TEXT_TITLE_ICON_ID_PREFIX = generateElementID(MAIN_PAGE_ID_PREFIX, 'texttitleicon')
export const TEXT_DESCRIPTION_ICON_ID_PREFIX = generateElementID(MAIN_PAGE_ID_PREFIX, 'textdescriptionicon')
export const IMAGE_CONTENT_ID_PREFIX = generateElementID(MAIN_PAGE_ID_PREFIX, 'imagecontent')

export const LOGO_PARTNER_ID_PREFIX = generateElementID(MAIN_PAGE_ID_PREFIX, 'logopartner')
export const CONTENT_LIST_ID_PREFIX = generateElementID(MAIN_PAGE_ID_PREFIX, 'contentlist')
export const BUTTON_ID_PREFIX = generateElementID(MAIN_PAGE_ID_PREFIX, 'button')

export const CONTENT_TEXT_ID_PREFIX = generateElementID(FOOTER_ID_PREFIX, 'contenttext')
export const CONTENT_ID_PREFIX = generateElementID(FOOTER_ID_PREFIX, 'content')
export const CONTENT_QUICK_LINKS_ID_PREFIX = generateElementID(FOOTER_ID_PREFIX, 'contentquicklinks')
export const CONTENT_SOLUTIONS_ID_PREFIX = generateElementID(FOOTER_ID_PREFIX, 'contentsolutions')
export const CONTENT_HELP_ID_PREFIX = generateElementID(FOOTER_ID_PREFIX, 'contenthelp')
