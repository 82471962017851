import React, { forwardRef } from 'react'
import 'react-intl-tel-input/dist/main.css'
import IntlTelInput from 'react-intl-tel-input'
import './input-phone.css'
import { Container } from './style'
import PropTypes from 'prop-types'

const propTypes = {
  type             : PropTypes.string,
  placeholder      : PropTypes.string,
  error            : PropTypes.string,
  name             : PropTypes.string,
  value            : PropTypes.string,
  title            : PropTypes.string,
  defaultCountry   : PropTypes.string,
  onChange         : PropTypes.func,
  maxLength        : PropTypes.number,
  handleSelectFlag : PropTypes.func
}

const defaultProps = {
  type             : 'text',
  placeholder      : 'what are you thinking?',
  title            : '',
  error            : '',
  value            : '',
  name             : '',
  defaultCountry   : 'id',
  onChange         : () => {},
  maxLength        : 15,
  handleSelectFlag : () => {}
}

const InputPhone = forwardRef(({
  placeholder,
  title,
  error,
  value,
  name,
  maxLength=15,
  onChange,
  defaultCountry,
  handleSelectFlag,
  onBlur,
  ...field
}, ref) => {

  const handleChangePhone = (...args) => {
    const newVval = args[1]
    if (newVval.length <= maxLength) onChange(...args)
  }

  const handleBlur = (isValid, phone, country) => {
    onBlur(isValid, phone, country)
  }


  return (
    <Container error={ error }>
      <span>{ title }</span>
      <IntlTelInput
        ref={ field.ref }
        placeholder={ placeholder }
        value={ value }
        defaultCountry={ defaultCountry.toLowerCase() }
        inputClassName={ 'phone' }
        onPhoneNumberChange={ handleChangePhone }
        separateDialCode
        onSelectFlag = { handleSelectFlag }
        onPhoneNumberBlur = { handleBlur }
        { ...field }
      />
      { error && <span style={{ marginBottom: '0.75rem' }}>{ error }</span> }
    </Container>
  )
})

InputPhone.propTypes = propTypes
InputPhone.defaulProps = defaultProps

export default InputPhone
