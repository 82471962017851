import styled from "styled-components";
import { IS_FRAME_DESKTOP } from "utils";

const DesktopOnly = styled.div`
  display: none;

  ${IS_FRAME_DESKTOP} {
    display: block;
  }
`;

export default DesktopOnly;
