import React, { forwardRef } from 'react'
import { Select } from 'components'
import { Wrapper } from './styles'
import useCountries from 'domains/Register/form/models/useCountries'
import { FormattedMessage } from 'utils/intl'

const InputCountry = forwardRef(({
  withSearch,
  keySearch,
  svgPosition,
  borderColor,
  py,
  errorText,
  selectedItem,
  selectedKey,
  placeholder,
  onChange
}, ref) => {

  const countries = useCountries()

  return (
    <Wrapper>
      <span><FormattedMessage id="857111d26" /></span>
      <Select
        withSearch={ withSearch }
        keySearch={ keySearch }
        svgPosition={ svgPosition }
        borderColor={ borderColor }
        py={ py }
        options={ countries.list }
        errorText={ errorText }
        selectedItem={ () => {
          const selected = countries.list.find((element) => element.countryName === selectedItem.countryName)
          return selected
        } }
        selectedKey={ selectedKey }
        placeholder={ placeholder }
        onChange={ onChange }
      />
    </Wrapper>
  )
})

export default InputCountry
