import { darkBlueGrey } from 'components/baseColor'
import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 0.5rem 0 0.75rem;

  > span {
    font-size: 14px;
    color: ${darkBlueGrey};
  }
`