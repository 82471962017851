import React, { useEffect, useState , useCallback } from 'react'
import { ItemCountry } from '../style'
import COUNTRIES from 'utils/countries.json'
import { graphql, useStaticQuery } from 'gatsby'


const useCountries = () => {

  const [flagURLObj, setFlagURLObj] = useState({})

  const flags = useStaticQuery(graphql`
    query {
      allFile(filter: {extension: {eq: "svg"}, relativeDirectory: {eq: "flag"}}) {
        edges {
          node {
            publicURL
          }
        }
      }
    }
  `)

  const handleFormatFlagURL = useCallback(() => {
    flags.allFile.edges.map((flag) => {
      // publicURL example: /static/76a9d87acd1b630f87449721eb91135f/AC.svg
      const alpha2Code = flag.node.publicURL.split('/').pop().toLowerCase() // ac.svg

      setFlagURLObj((prevValue) => ({
        ...prevValue,
        [alpha2Code]: flag.node.publicURL
      }))
    })
  }, [flags])

  useEffect(() => {
    handleFormatFlagURL()
  }, [handleFormatFlagURL])

  const countryList = COUNTRIES.map((country) => {
    const sizeImg = 20

    return {
      ...country,
      countryName : country.name,
      id          : country.alpha2Code,
      name        : (
        <ItemCountry>
          <img 
            src={ flagURLObj[`${country.alpha2Code}.svg`] } 
            height={ `${sizeImg}px` } 
            width={ `${sizeImg}px` } 
            alt={ country.name }
          />
          <span>{ country.name }</span>
        </ItemCountry>
      )
    }
  })
    

  return { list: countryList }
}

export default useCountries
