import styled from 'styled-components'
import { darkBlueGrey } from '../baseColor'
import { IS_FRAME_DESKTOP, IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Wrapper = styled.div`
  background-color: #fff;
  display: flex;
  padding: 2em 4em;
  color: ${darkBlueGrey};
  justify-content: space-between;
  max-width: 1600px;
  margin: auto;

  > :nth-of-type(1) {
    flex: 0 0 40%;

    > svg {
      width: 8rem;
      height: 6rem;
    }
  }

  .wrapper-sosmed {
    display: flex;
    margin: 1.4em 0;
    width: 200px;
    justify-content: space-between;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 1.4em 0 0 0;

    li {
      display: flex;
      margin: 0.75rem 0;
      color: ${darkBlueGrey};
      text-transform: capitalize;

      &:last-of-type {
        margin-bottom: 0em;
      }

      > :nth-of-type(1) {
        margin-right: 1em;
      }

      > :nth-of-type(2) {
        align-self: center;
      }

      svg {
        path {
          fill: ${darkBlueGrey};
        }
      }
    }
  }

  .title {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 1.3em;
    text-transform: capitalize;
  }

  a {
    text-decoration: none;
  }

  ${IS_FRAME_TABLET} {
    a {
      font-size: 0.875rem;
    }
  }

  ${IS_FRAME_TABLET} {
    .each-section:nth-child(4) {
      display: flex;
      flex: 0 0 100%;
      > div {
        flex: 0 0 50%;
      }
    }
  }

  ${IS_FRAME_TABLET} {
    flex-wrap: wrap;
    justify-content: flex-start;
  
    .each-section {
      padding-bottom: 2em;
    }
  }

  .flexAffiliate {
    margin-bottom: 1rem;
  }

  ${IS_FRAME_TABLET} {
    flex-wrap: wrap;
    justify-content: flex-start;

    > :nth-of-type(1) {
      align-self: baseline;
      padding-bottom: 2em;
      flex: 0 0 100%;
      > svg {
        width: 8rem;
        height: 6rem;
      }
    }

    > :nth-of-type(2) {
      flex: 0 0 50%;
    }
    
    > :nth-of-type(3) {
      flex: 0 0 50%
    } 

    img {
      width: 50%;
      padding-top: 2em;
    }
  }

  ${IS_FRAME_MOBILE} {
    padding: 2em;
    display: block;

    .help {
      flex-direction: column;
    }

    .flexAffiliate {
      margin-bottom: 1rem;
    }
  }

  ${IS_FRAME_MOBILE} {
    flex-direction: column;
  
    .each-section {
      padding-bottom: 2em;
    }
  
    .line {
      display: none;
    }
  }
  
  ${IS_FRAME_MOBILE} {
    flex-direction: column;
  
    > :nth-of-type(1) {
      align-self: baseline;
      padding-bottom: 2em;
  
      > svg {
        width: 8rem;
          height: 6rem;
      }
    }
  
    img {
      width: 50%;
      padding-top: 2em;
    }
  }

  ${(props) => props.isCartPage &&
    `
    ${IS_FRAME_DESKTOP} {
      border-top: 1px solid #222b49;
      margin-top: 0.01rem
      width: calc(100% - 497px);
      box-shadow: 0px 0 0px 0 rgb(0 0 0 / 25%);
    }
  `}
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
  line-height: 1.5;
  width: 75%;

  > svg {
    flex: 0 0 2rem;
    margin-right: 1rem;
  }

  ${IS_FRAME_TABLET} {
    > span {
      font-size: 0.875rem;
    }
  }

  ${IS_FRAME_MOBILE} {
    width: 100%;
    > svg {
      flex: 0 0 2rem;
      margin-right: 1rem;
    }
  }
`

export const Sosmed = styled.a`
  position: relative;

  .tooltip {
    display: none;
  }

  &:hover {
    .tooltip {
      display: block;
      position: absolute;
      left: -2rem;
      background: #e7e8eb;
      border-radius: 4px;
      padding: 2px 4px;
      color: ${darkBlueGrey};
      width: 5.5rem;
      text-align: center;
    }
  }
`