import styled from 'styled-components'
import { Link } from 'gatsby'
import { darkBlueGrey } from 'components/baseColor'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Title = styled.h2`
  color: ${darkBlueGrey};
  text-align: center;
  margin: 1rem 0 1.5rem;
  font-size: 1.5rem;

  ${IS_FRAME_MOBILE} {
    font-size: 1.2rem;
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;

  ${IS_FRAME_TABLET} {
    grid-template-columns: 1fr; 
  }

  ${IS_FRAME_MOBILE} {
    grid-template-columns: 1fr; 
  }
`

export const Card = styled(Link)`
  padding: 1rem;
  border-radius: 4px;
  border: solid 1px #dfe1e5;
  background-color: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  position: relative;

  ${IS_FRAME_TABLET} {
    padding: 2rem;
  }
`

export const SolutionImg = styled.img`
  display: block;
  margin: 0 auto;
  width: 8rem;

  ${IS_FRAME_MOBILE} {
    width: 10rem;
  }
`

export const SolutionTitle = styled.div`
  font-weight: 600;
  color: ${darkBlueGrey};
  line-height: 1.56;
  text-align: center;
  font-size: 0.95rem;
  margin: 1rem 0;
  text-decoration: none;
  color: ${darkBlueGrey};

  ${IS_FRAME_TABLET} {
    font-size: 1.2rem;
  }
`

export const SolutionDetail = styled.div`
  color: #9095a4;
  line-height: 1.75;
  font-size: 0.85rem;
  margin-bottom: 2.5rem;

  ${IS_FRAME_TABLET} {
    font-size: 1rem;
  }
`

export const SeeDetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2em;
  font-weight: 800;
  letter-spacing: 0.5px;
  color: ${darkBlueGrey};
  align-self: flex-end;
  margin-top: 1rem;
  position: absolute;
  bottom: 1rem;

  > svg path {
    fill: ${darkBlueGrey};
  }
`