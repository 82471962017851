import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Spinner } from "@qlue/icon-kit";

import { BaseComponent } from "./styles";

const Button = forwardRef(
  ({ children, buttonType, onClick, isLoading = false, disabled = false, ...props }, ref) => (
    <BaseComponent
      {...props}
      ref={ref}
      onClick={onClick}
      buttonType={buttonType || props.type}
      disabled={isLoading || disabled}
    >
      {isLoading ? <Spinner width="15px" /> : children}
    </BaseComponent>
  )
);

Button.propTypes = {
  isLoading: PropTypes.bool,
  buttonType: PropTypes.oneOf(["warning", "primary", "light"]),
};

export default Button;
