import styled, { keyframes } from 'styled-components'
import { darkBlueGrey } from 'components/baseColor'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

const slide = keyframes`
    from {
        margin-left: 20px;
    }
    to {
        margin-left: 0px;
    }
`

export const FormRegister = styled.form`
  width: 100%;

  ${IS_FRAME_TABLET} {
    width: 100%;
  }
`

export const BoxRegister = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  padding: 20px 50px 30px 35px;
  animation-name: ${slide};
  animation-duration: 1s;
  background-color: #fff;

  ${IS_FRAME_TABLET} {
    padding: 36px 30px 59px 30px;
  }

  ${IS_FRAME_MOBILE} {
    padding: 1rem 1.25rem 2rem;
  }
`

export const TitleRegister = styled.div`
  font-size: 1.7em;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.43px;
  color: ${darkBlueGrey};
  text-transform: uppercase;

  ${IS_FRAME_MOBILE} {
    font-size: 1.5em;
    text-align: center;
  }
`

export const DeskripsiRegister = styled.div`
  font-size: 1.1em;
  margin-bottom: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  color: ${darkBlueGrey};
  ${IS_FRAME_MOBILE} {
    text-align: center;
  }
`

export const CheckUpdate = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 10px;
`

export const TextUpdate = styled.div`
    display: flex;
    flex-direction: column;

    .terms-and-conditions {
      color: #e9bc24;
      font-weight: bold;
      cursor: pointer;
      text-decoration: none;
    }
    span: nth-child(1),
    span: nth-child(2) {
        font-family: "Open Sans";
        font-size: 9px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.25px;
        ${IS_FRAME_MOBILE} {
          font-size: 10px;
        }
    }
    span: nth-child(1) {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 2px;
        ${IS_FRAME_MOBILE} {
          font-size: 12px;
        }
    }
    
`
export const AccountRendering = styled.div`
    font-family: "Open Sans";
    display: flex;
    margin-top: 20px;

    span: nth-child(1),
    span: nth-child(2),
     {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.25px;
    }

    span: nth-child(2) {
        color: ${darkBlueGrey};
        font-weight: bold;
        margin-left: 10px;
        cursor: pointer;
        a {
          text-decoration: none;
        }
    }
`

export const ButtonSubmit = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const ItemCountry = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: center;
  grid-gap: 9px;

  img {
    object-fit: contain;
  }
`
