import React, { useState, forwardRef  } from 'react'
import PropTypes from 'prop-types'
import { VisibilityOff, VisibilityOn } from '@qlue/icon-kit'
import { Container, WrapperEyeIcon } from './style'
import { generateElementID, FIELD_PASSWORD_ID_PREFIX } from 'utils/generateElementID'


const InputText = forwardRef(({ height, withSpaceError = true, type, onBlur, title, error, ...field }, ref) => {

  const [showPass, setShowPass] = useState(() => !!((type === 'text' || type === 'email')))
  const toggleShowPass = () => setShowPass(!showPass)

  const handleBlur = (e) => {
    onBlur(e.target.name, e.target.value)
  }

  return (
    <Container { ...{ height, withSpaceError, error } }>
      <span>{ title }</span>
      <input { ...field } type={ showPass ? 'text' : 'password' } onBlur={ handleBlur } maxLength={ type === 'email' ? '50' : '' } />
      { error && <span style={{ marginBottom: '0.75rem' }}>{ error }</span> }
      { type === 'password' && (
        <WrapperEyeIcon 
          hasError={ error } 
          onClick={ toggleShowPass }
          id={ generateElementID(FIELD_PASSWORD_ID_PREFIX, 'btnshoworhide') }
        >
          { showPass ? <VisibilityOn /> : <VisibilityOff /> }
        </WrapperEyeIcon>
      ) }
    </Container>
  )
})

InputText.defaultProps = {
  type        : 'text',
  placeholder : 'what are you thinking?',
  title       : '',
  error       : '',
  value       : '',
  name        : '',
  onBlur      : () => {},
  onChange    : () => {}
}

InputText.propTypes = {
  title       : PropTypes.string,
  type        : PropTypes.string,
  placeholder : PropTypes.string,
  error       : PropTypes.string,
  value       : PropTypes.string,
  onBlur      : PropTypes.func.isRequired,
  onChange    : PropTypes.func
}
export default InputText
