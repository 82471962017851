import React from 'react'
import { ArrowRight } from '@qlue/icon-kit'
import {
  Title,
  Grid,
  Card,
  SolutionImg,
  SolutionTitle,
  SolutionDetail,
  SeeDetailWrapper
} from './styles'
import { FormattedMessage } from 'utils/intl'
import Solution1 from './media/solution-1.svg'
import Solution2 from './media/solution-2.svg'
import Solution3 from './media/solution-3.svg'

const SOLUTION_DATA = [
  {
    title  : <FormattedMessage id="8e44f019d" />,
    detail : <FormattedMessage key="848b142ec" id="848b142ec" />,
    link   : '/solution/smart-workforce-management',
    icon   : Solution1
  },
  {
    title  : <FormattedMessage id="2bdcfb17a" />,
    detail : <FormattedMessage key="695b842da" id="695b842da" />,
    link   : '/solution/human-detection-and-recognition',
    icon   : Solution2
  },
  {
    title  : <FormattedMessage id="c9b8e2233" />,
    detail : <FormattedMessage key="54776175b" id="54776175b" />,
    link   : '/solution/vehicle-detection-and-recognition',
    icon   : Solution3
  }
]

const SmartSolution = () => (
  <>
    <Title>
      <FormattedMessage id="002f8b01a" />
    </Title>
    <Grid>
      { 
        SOLUTION_DATA.map((item, i) => (
          <Card 
            key={ `solution ${i}` }  
            to={ item.link }
          >
            <SolutionImg 
              src={ item.icon } 
              alt={ `solution ${i}` } 
            />
            <SolutionTitle>
              { item.title }
            </SolutionTitle>
            <SolutionDetail>{ item.detail }</SolutionDetail>
            <SeeDetailWrapper>
              <FormattedMessage id="c37312b6b" />
              <ArrowRight />
            </SeeDetailWrapper>
          </Card>
        )) 
      }
    </Grid>
  </>
)
export default SmartSolution
