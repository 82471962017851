import { coolGrey, darkBlueGrey } from "components/baseColor";
import styled, { css } from "styled-components";
import { IS_FRAME_MOBILE } from "utils";

export const Steps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem 8rem 0;

  ${IS_FRAME_MOBILE} {
    margin: 2rem 3rem 0;
  }
`;

export const Step = styled.div`
  width: 1rem;
  height: 1rem;
  background: ${coolGrey};
  border: 2px solid ${coolGrey};
  border-radius: 50%;
  transition: background 1s;
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    css`
      background: #fff;
      border: 2px solid ${darkBlueGrey};
    `}

  ${({ completed }) =>
    completed &&
    css`
      border: 2px solid ${darkBlueGrey};
      background: ${darkBlueGrey};
    `}
`;

export const Line = styled.hr`
  border: 1px solid ${coolGrey};
  width: 100%;
  transition: width 1s;

  ${({ active }) =>
    active &&
    css`
      border: 1px solid ${darkBlueGrey};
      background: ${darkBlueGrey};
    `}
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 6rem 2rem;

  ${IS_FRAME_MOBILE} {
    margin: 0 1rem 1.5rem;
  }
`;

export const Text = styled.p`
  font-size: ${({ fontSize }) => fontSize || "1.15em"};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  text-align: ${({ textAlign }) => textAlign || "left"};
  margin: ${({ m }) => m};
  color: ${darkBlueGrey};

  ${({ shaded }) =>
    shaded &&
    css`
      color: ${coolGrey};
      font-size: 0.95rem;
    `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: bolder;
    `}
`;
